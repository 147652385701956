<template>
  <section>
    <b-overlay
      :show="loadingSpinner"
      rounded="sm"
      opacity="1.6"
      spinner-small
      spinner-variant="secondary"
    >
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-center mb-4">
          <div class="align-items-center mb-4">
            <h4 class="text-center text-secondary">Upload dos arquivos.</h4>
          </div>

          <VueDropzone
            id="uploadComponent"
            class="vue-dropzone"
            ref="UploadComponentDropzone"
            :options="dropzoneOptions"
            @vdropzone-success="handleUploadSuccess"
            @vdropzone-error="handleUploadError"
            @vdropzone-file-added="handleFileAdded"
            @vdropzone-sending="sendingEvent"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Arraste e solte aqui seus arquivos.
              </h3>
              <div class="subtitle">... ou clique para abrir o diretório.</div>
            </div>
          </VueDropzone>

          <button
            v-if="Uploadok"
            class="btn-exportar mt-10 align-items-center"
            @click="SendToConvert(0)"
            download
          >
            <strong> Relatorio CSV</strong>
          </button>

          <!-- <button
            v-if="Uploadok"
            class="btn-exportar mt-10 align-items-center"
            @click="SendToConvert(1)"
            download
          >
            <strong> Relatorio CSV Desonerada</strong>
          </button> -->
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import Vue2Dropzone, { data } from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import RelatorioService from "@/services/relatorio.service";

export default {
  name: "UploadComponentConvertCsv",
  components: {
    DotAnimation,
    VueDropzone: Vue2Dropzone,
  },

  data() {
    return {
      showOverlay: false,
      loadingSpinner: false,
      isLoaded: false,
      FileUpload: "",
      DataForConvert: [],
      NameFile: "",
      Uploadok: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_URL_API + "/ConvertCsv/upload/",
        addRemoveLinks: false,
        duplicateCheck: true,
        parallelUploads: 1,
        autoProcessQueue: true,
        acceptedFiles: ".xlsx",
        paramName: "files",
        timeout: 0,
        maxFiles: 10,
        maxFilesize: 999999999,
      },
    };
  },

  props: { paramsImportFiles: Object },

  mounted() {
    this.isLoaded = false;
  },

  watch: {},

  methods: {
    sendingEvent(file, xhr, formData) {},

    handleUploadSuccess(file, response) {
      console.log("response", response);
      this.FileUpload = file;
      this.NameFile = file.upload.filename;
      if (response.sucesso) {
        try {
          this.loadingSpinner = false;
          this.DataForConvert = response.dados;
          this.$refs.UploadComponentDropzone.removeFile(file);
          this.Uploadok = true;
        } catch (error) {
          console.error("error ->", error);
          this.$refs.UploadComponentDropzone.removeFile(file);
          this.$toast.open({ message: error, type: "error", duration: 4000 });
        }
      }
      if (response.mensagem.length != null) {
        if (response.mensagem.length > 0) {
          response.mensagem.forEach((element) => {
            this.$toast.open({
              message: element,
              type: "error",
              duration: 4000,
            });
            this.$refs.UploadComponentDropzone.removeFile(file);
          });
        }
      }
    },

    handleUploadError(file, response, xhr) {
      console.log("handleUploadError");
      console.log("response", response);
      this.FileUpload = file;
      var error = "Erro ao enviar";
      if (response.mensagem != null) {
        error = response.mensagem[0];
      } else {
        this.$refs.UploadComponentDropzone.removeFile(this.FileUpload);
        this.$toast.open({ message: error, type: "error", duration: 4000 });
      }
    },

    handleFileAdded(file) {
      this.FileUpload = file;
    },

    async SendToConvert(tipo) {
      console.log("export CSV");
      this.loadingSpinner = true;

      await RelatorioService.SendToConvert(this.DataForConvert, tipo).then(
        (response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.NameFile + ".csv");
          document.body.appendChild(link);
          link.click();
        }
      );
      this.loadingSpinner = false;
      this.Uploadok = false;
    },
  },
};
</script>

<style scoped>
#uploadComponent {
  border: 2px dashed #aaa !important;
  color: #aaa;
  background-color: #eee !important;
  width: 100% !important;
}

.toast-error-tj {
  background-color: aqua !important;
}

VueDropzone .dz-preview .dz-details {
  background-color: #fff !important;
  color: black !important;
}

.btn-tj-blue {
  color: #233873 !important;
  border: 1px solid #233873 !important;
}

.btn-tj-blue:hover {
  background-color: #233873 !important;
  color: #fff !important;
}

#customdropzone {
  background-color: orange;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

.btn-exportar {
  border: 1px solid #233873;
  color: #233873;
  margin: 3% 3%;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}
</style>
