<template>
  <section>
    <div class="container jumbotron">
      <UploadComponentConvertCsv />
    </div>
  </section>
</template>

<script>
import UploadComponentConvertCsv from "@/Components/UploadComponent/UploadComponentConvertCsv.vue";

export default {
  name: "ConvertInCsv",
  components: {
    UploadComponentConvertCsv,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
